.wrapper {
    border: 1px dashed black;
    border-radius: 16px;
    padding: 20px;
    background: #F4F4F4;
}

.warapper2 {
    display: flex;
    justify-content: space-around;
}

.addPhotoLabel {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.preview {
    border-radius: 16px;
    display: flex;
    padding: 32px;
    gap: 32px;
    overflow-x: scroll;
    background: #F4F4F4;
}

.previewImage {
    object-fit: cover;
    width: 340px;
    height: 232px;
    border-radius: 16px;
}

.imageWrapper {
    position: relative;
}

.removeButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}