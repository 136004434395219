.navBar {
    display: flex;
    align-items: center;
    padding: 0;
}

.userpicName {
    margin: 0;
}

.navBarButtons {
    display: flex;
    gap: 12px;
    margin-left: auto;
}

.groupTitle {
    margin-top: 24px;
}

.flex12pxGap {
    display: flex;
    gap: 12px
}

.marginTop12 {
    margin-top: 12px;
}

.marginTop48 {
    margin-top: 48px;
}

.userpicImg {
    border-radius: 12px;
}

.userpicPanel {
    align-items: center;
    display: flex;
    gap: 12px;
}

.offerCart {
    padding: 32px;
    margin-top: 24px;
    border-radius: 16px;
    border: 1px solid #19191C33;
}

.header {
    display: flex;
    justify-content: space-between;
}

.host {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.dates {
    margin-top: 24px;
}

.carousel {
    margin-top: 32px;
}

.ownerBlock {
    display: flex;
    justify-content: flex-end;
}

.contactButtonsBlock {
    display: flex;
    gap: 12px;
    margin-top: 24px;
}

.titleUnderCarousel {
    margin-top: -54px;
}

.container {
    height: 448px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 32px 32px;
    grid-template-areas:
    "BigOne BigOne A"
    "BigOne BigOne B";
}
.BigOne { grid-area: BigOne; }
.A { grid-area: A; }
.B { grid-area: B; }

.contentContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 32px 32px;
    grid-template-areas:
    "info info map";
}
.info { grid-area: info; }
.mapContainer { grid-area: map;  }
.map {
    border-radius: 16px;
    overflow: hidden;
    max-height: 208px;
}

.soloImage {
    height: 608px;
    border-radius: 16px;
    margin-top: 24px;
}