
.wrapper {
    display: flex;
    gap: 12px;
}

.pane {
    position: absolute;
    border: 1px solid black;
    border-radius: 5px;
    z-index: 10;
    top: 48px;
    background: white;
}