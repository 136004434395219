.card {
    width: 384px;
    max-width: 384px;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardWrapper {
    flex-grow: 1;
}

.user {
    display: flex;
    align-items: center;
}

.cardContent {
    margin-bottom: 16px;
}

.list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

.pets {
    margin-right: 8px;
    padding: 2px 8px;
    border-radius: 4px;
    background-color: var(--rs-color-grey-5);
}

.button {
    margin-right: 16px;
}

.image {
    border-radius: 16px;
    object-fit: cover;
    width: 100%;
    height: 208px;
}

.cityDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.digits {
    font-size: 19px;
    line-height: 1;
}

.care {
    position: absolute;
    left: 16px;
    bottom: 16px;
}

.imageWrapper {
    position: relative;
    margin-bottom: 16px;
}

.address {
    margin-bottom: 32px;
}