@import '@rescui/colors/lib/index.css';

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow-y: scroll;
}

#root {
    height: 100%;
}

* {
    box-sizing: border-box;
}

.rdp {
    --rdp-cell-size: 40px;
    --rdp-accent-color: #19191C;
    --rdp-background-color: #5e5e61;
    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);
    /* Outline border for focused and selected elements */
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

.rdp-head, .rdp-tbody, .rdp-caption_label {
    font-family: 'JetBrains Sans';
}