.navBar {
    display: flex;
    align-items: center;
    padding: 0;
}

.userpicName {
    margin: 0;
}

.navBarButtons {
    display: flex;
    gap: 12px;
    margin-left: auto;
}

.groupTitle {
    margin-top: 24px;
}

.flex12pxGap {
    display: flex;
    gap: 12px
}

.marginTop12 {
    margin-top: 12px;
}

.marginTop48 {
    margin-top: 48px;
}

.userpicImg {
    border-radius: 12px;
    object-fit: cover;
}

.userpicPanel {
    align-items: center;
    display: flex;
    gap: 12px;
}

/* ****************** */

.careWord {
    position: relative;
}

.careWord:before {
    position: absolute;
    z-index: -2;
    content: '';
    background-size: contain;
    background-image: url("./word-care.png");
    background-repeat: no-repeat;
    left: -28px;
    top: -68px;
    width: 150px;
    height: 68px;
}

/* ****************** */

.locationWord {
    position: relative;
}

.locationWord:before {
    position: absolute;
    z-index: -2;
    content: '';
    background-size: cover;
    background-image: url("./word-location.png");
    background-repeat: no-repeat;
    left: -28px;
    top: -50px;
    width: 130px;
    height: 54px;
}

/* ****************** */

.generalInfoWord {
    position: relative;
}

.generalInfoWord:before {
    position: absolute;
    z-index: -2;
    content: '';
    background-size: contain;
    background-image: url("./word-general-info.png");
    background-repeat: no-repeat;
    left: -41px;
    top: -65px;
    width: 190px;
    height: 86px;
}

.petImages {
    margin-bottom: 50px;
}