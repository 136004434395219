.root {
    margin-bottom: 32px;
    display: flex;
}

.buttonsBlock {
    display: flex;
    margin-left: auto;
    gap: 12px;
    height: 40px;
}