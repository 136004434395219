.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 1280px;
    padding: 24px 32px 0 32px;
}

.header {
    font-weight: bold;
    margin-bottom: 32px;
}

.logo {
    width: 100%;
}

.main {
    margin-bottom: 32px;
}

.wrapper {
    flex-grow: 1;
}

.footer {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid black;
}

button.slackButton {
    padding: 8px;
    width: 32px;
    height: 32px;
    line-height: 1;
}