.title {
    font-style: normal;
    font-weight: 300;
    font-size: 70px;
    line-height: 120%;
}

.signinBtn {
    vertical-align: 0.8em;
    margin-left: 12px;
}

/* ****************** */

.sitters {
    position: relative;
}

.sittersWord:before {
    position: absolute;
    z-index: -1;
    content: '';
    background-size: contain;
    background: url("./word-sitters.png") no-repeat;
    width: 3.3em;
    height: 2.0em;
}

/* ****************** */

.petWord {
    position: relative;
}

.petWord:before {
    position: absolute;
    z-index: -2;
    content: '';
    background-size: contain;
    background: url("./word-pet.png") no-repeat;
    left: -18px;
    width: 2.8em;
    height: 2em;
}

/* ****************** */

.theMostCaringWord {
    position: relative;
}

.theMostCaringWord:before {
    position: absolute;
    z-index: -1;
    content: '';
    background-size: contain;
    background: url("./word-the-most-caring.png") no-repeat;
    width: 8em;
    top: 1.1em;
    height: 2em;
}

/* ****************** */

.homesWord {
    position: relative;
}

.homesWord:before {
    position: absolute;
    z-index: -1;
    content: '';
    background-size: contain;
    background: url("./word-homes.png") no-repeat;
    left: -30px;
    top: 6px;
    width: 3.8em;
    height: 2em;
}

/* ****************** */

.swapWord {
    position: relative;
}

.swapWord:before {
    position: absolute;
    z-index: -1;
    content: '';
    background: url("./word-swap.png") no-repeat;
    background-size: contain;
    top: -6px;
    width: 2.8em;
    height: 2em;
}